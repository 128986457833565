import React from "react";
import Fetch from "./Fetch";

class Search extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            display: this.props.display || false,
            search: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    //alert('A name was submitted: ' + this.state.value);
    this.setState({search: true});
    event.preventDefault();
  }


    render(){
        return(
            <div>
            <form className="form-inline my-2 my-lg-0" onSubmit={this.handleSubmit}>
                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" value={this.state.value} onChange={this.handleChange} />
                <button className="btn btn-outline-success my-2 my-sm-0" type="submit" value="submit">Search</button>
            </form>

                {this.state.search ? (
                  <div>{this.state.display ? <Fetch search={this.state.value} />: null}</div>
                ) : (
                    null
                )}
            </div>
        );
    }
}

export default Search;