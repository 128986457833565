import React from "react";
//import Card from "./Card";

class Fetch extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            search : props.search
        };
    }

    componentDidMount() {
        if(this.state.search !== "") {
            let OMDBUrl = "https://www.omdbapi.com/?t=" + this.state.search + "&apikey="
            let ApiKey = process.env.OMDB_API_Key || "5c334e36";
            //console.log(process.env.OMDB_API_Key);
            //console.log(process.env);
            fetch(OMDBUrl + ApiKey)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            Title: result.Title,
                            Year: result.Year,
                            Rated: result.Rated,
                            Released: result.Released,
                            Runtime: result.Runtime,
                            Genre: result.Genre,
                            Director: result.Director,
                            Writer: result.Writer,
                            Actors: result.Actors,
                            Plot: result.Plot,
                            Language: result.Language,
                            Country: result.Country,
                            Awards: result.Awards,
                            Poster: result.Poster,
                            /*Ratings1: result.Ratings[0].Source,
                            Ratings2: result.Ratings[0].Value,
                            Ratings3: result.Ratings[1].Source,
                            Ratings4: result.Ratings[1].Value,
                            Ratings5: result.Ratings[2].Source,
                            Ratings6: result.Ratings[2].Value,*/
                            Metascore: result.Metascore,
                            imdbRating: result.imdbRating,
                            imdbVotes: result.imdbVotes,
                            imdbID: result.imdbID,
                            Type: result.Type,
                            DVD: result.DVD,
                            BoxOffice: result.BoxOffice,
                            Production: result.Production,
                            Website: result.Website,
                            Response: result.Response,
                            Result: result
                        });
                        //console.log((result));
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    render() {
        return(
            <div>
            {this.state.isLoaded ? (

                /*<Card
                        Title={this.state.Title}
                        imageUrl={this.state.Poster}
                        Plot={this.state.Plot}
                        Released={this.state.Released}
                        Runtime={this.state.Runtime}
                        Genre={this.state.Genre}
                    />


                    <Card
                        Title="The Avengers"
                        imageUrl="https://m.media-amazon.com/images/M/MV5BNDYxNjQyMjAtNTdiOS00NGYwLWFmNTAtNThmYjU5ZGI2YTI1XkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_SX300.jpg"
                        Plot="Earth's mightiest heroes must come together and learn to fight as a team if they are going to stop the mischievous Loki and his alien army from enslaving humanity"
                        Released="04 May 2012"
                        Runtime="143 min"
                        Genre="Action, Adventure, Sci-Fi"
                    />*/

                <div>
                    {console.log("STATE ",this.state.Result.Response)}
                    {this.state.Result.Response!=="False" ?(
                        <div>
                    <h1>Title: {this.state.Title}</h1>
                    <p>Year: {this.state.Year}</p>
                    <p>Rated: {this.state.Rated}</p>
                    <p>Released: {this.state.Released}</p>
                    <p>Runtime: {this.state.Runtime}</p>
                    <p>Genre: {this.state.Genre}</p>
                    <p>Director: {this.state.Director}</p>
                    <p>Writer: {this.state.Writer}</p>
                    <p>Actors: {this.state.Actors}</p>
                    <p>Plot: {this.state.Plot}</p>
                    <p>Language: {this.state.Language}</p>
                    <p>Country: {this.state.Country}</p>
                    <p>Awards: {this.state.Awards}</p>
                      <p>Poster: <img src={this.state.Poster} alt="Poster"/></p>
                    <h6>Ratings</h6>
                    <p>{this.state.Ratings1}: {this.state.Ratings2}</p>
                    <p>{this.state.Ratings3}: {this.state.Ratings4}</p>
                    <p>{this.state.Ratings5}: {this.state.Ratings6}</p>
                    <p>Metascore: {this.state.Metascore}</p>
                    <p>IMDB Rating: {this.state.imdbRating}</p>
                    <p>IMDB Votes:{this.state.imdbVotes}</p>
                    <p>IMDB ID{this.state.imdbID}</p>
                    <p>Type: {this.state.Type}</p>
                    <p>DVD: {this.state.DVD}</p>
                    <p>Box Office: {this.state.BoxOffice}</p>
                    <p>Production: {this.state.Production}</p>
                    <p>Website: {this.state.Website}</p>
                    <p>Response: {this.state.Response}</p></div>
            ):(
                <p>Please refresh and search by another movie name :)</p>
            )}
                </div>
                ) : (
                <p>Please search by a movie name :)</p>
                )}
                </div>
        );
    }
}
export default Fetch;