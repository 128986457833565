import React from 'react';
import Search from "./Search";

class Header extends React.Component{
    constructor(props) {
    super(props);
    this.state = {
        value: ''
    };
  }


  render() {

        return (
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="/navbarTogglerDemo01"
                      aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                  <a className="navbar-brand" href="/">NetCritique</a>
                  <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                      <li className="nav-item active">
                          <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link" href="/">Link</a>
                      </li>
                      <li className="nav-item">
                          <a className="nav-link disabled" href="/">Disabled</a>
                      </li>
                  </ul>
                  <Search />
              </div>
          </nav>
        );
  }
}

export default Header;