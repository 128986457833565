import React from 'react';
import Navbar from "./Components/Navbar"
import Search from "./Components/Search";

class Base extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
    };
  }

    render(){
        return (
          <div>
            <Navbar />
            <Search display={true}/>
            <p>Please search by a movie name :)</p>
          </div>
        );
    }
}


export default Base;
